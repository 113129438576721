/* Estilos padrão para telas maiores (desktop) */
.titulo  {
  margin-top: 3rem;
  color: #043434;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1em;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;

}

.elementor-divider {
  border: 3px solid #CE9728;
  width: 200px;
  margin: 0 auto;
  
  border-width: 3px;
  margin-bottom: 2rem;
  margin-top: 10px;
}


.Main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 70%;
}

.Main h3 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  color: #4a4a4a;
}

.Main h4 {
  font-family: Source Sans\3, sans-serif;
  font-size: 1.09rem;
  letter-spacing: 0.3px;
  line-height: 1.6;
}



.elemento2, .elemento1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 10vh;
  justify-content: center;
  border-style: solid;
  border-width: 0px 1px 1px 0px;
  border-color: #E1E1E1;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 30px 35px 28px;
  background-color: #F9F9F9;
}



.elemento1 h4,
.elemento2 h4 {
  letter-spacing: 0.3px; /* Define o espaçamento entre as letras */
}

.elemento1,
.elemento2 {
  min-height: 200px; /* Define a altura mínima dos elementos */
}

.elemento1 img, .elemento2 img {
  position: relative;
  transition: transform 0.3s ease-in-out;

}

.elemento1 img:hover, .elemento2 img:hover {
  transform: translateY(-10px);
}

.linha1, .linha2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* Estilos para telas menores (dispositivos móveis) */
@media screen and (max-width: 768px) {

  .titulo  {
    margin-top: 3rem;
    color: #043434;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.1em;
    font-family: 'Inter', sans-serif;
    text-transform: uppercase;
  
  }
  main h1 {
    margin-top: 4rem;
    font-size: 32px; /* Redefine o tamanho da fonte para dispositivos móveis */
  }

  .elementor-divider {
    width: 40px; /* Redefine a largura do divisor para dispositivos móveis */
  }

  .Main {
    flex-direction: column; /* Altera a direção da coluna para empilhar elementos */
    width: 90%; /* Redefine a largura para ocupar mais da largura da tela */
  }

  .Main h3 {
    font-size: 18px; /* Redefine o tamanho da fonte para dispositivos móveis */
  }

  .elemento1,
  .elemento2 {
    max-height: none;
    padding: 20px; /* Redefine o preenchimento para dispositivos móveis */
  }
}
