.fundo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 100vw;
  background-color: #1f1d1e;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.01)), url(./headerImg.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: cover;
}


  
  .logo {
    width: 900px; /* Adjust the width as needed */
    height: auto;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .Button_Whats {
    font-family: "Roboto", Sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    background-color: #AC8C6C;
    border-radius: 2rem;
    padding: 18px 30px 18px 30px;
    color: inherit;
    text-decoration: inherit;
    transition: ease-in 0.3s;
    margin-top: 6rem;
    text-align: center;
    color: white;
  }

  .Button_Whats:hover {
        background-color: white;
        color: #AC8C6C;
   }
  
  .text-container h1 {
    font-size: 2.5rem; /* Adjust the font size as needed */
    margin-bottom: 10px; /* Adjust spacing */
  }
  
  .text-container p {
    font-size: 1.2rem; /* Adjust the font size as needed */
    margin-bottom: 2rem;
  }
  

  @media screen and (max-width: 768px) {

    .fundo {
      height: 60vh;
    }

    .text-container {
      margin:auto; /* Redefine a margem para centralizar */
      text-align: center;
    }
  
    .logo {
      width: 360px; /* Ajusta o tamanho do logo para dispositivos móveis */
    }
  
    .Button_Whats {
      font-size: 16px; /* Ajusta o tamanho da fonte para dispositivos móveis */
      padding: 16px 40px; /* Ajusta o espaçamento para dispositivos móveis */
    }
  }