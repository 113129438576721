/* Footer container */
.footer {
  background-color: #043434;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer_container {
  display: flex;
  justify-content: space-around;
}

/* Footer logo */
.footer-logo {
  width: 300px; /* Ajuste o tamanho da logo conforme necessário */
  height: auto;
}

.footer-sociais {
  display: flex;
  align-items: center;
}

/* Footer icons */
.footer-icon {
  width: 40px;
  height: auto;
  margin-right: 1rem;
  filter: invert(1); /* Isso inverte as cores do ícone */
}

.footer-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: inherit;
  
}

.footer-contact p {
  font-size: 22px;
  line-height: 1.2;
  letter-spacing: 0.6px;
  margin-bottom: 2rem;
}

/* Remove anchor colors */
a {
  color: inherit;
  text-decoration: none;
}

/* Media query for mobile devices */
@media (max-width: 600px) {
    .footer_container {
        flex-direction: column;
    }
    
    .footer-logo {
        width: 200px; /* Ajuste o tamanho da logo conforme necessário */
    }
    
    .footer-contact p {
        font-size: 18px;
    }
}
